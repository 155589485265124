import React, { Component } from "react";
import { Helmet } from "react-helmet";

class PageHelmet extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.props.pageTitle} || Iglesia Full Life
            
          </title>
          <meta
            name="description"
            content="Iglesia cristiana en el sur de la florida. Viviendo por principios y no por emociones.
Guiados por la Palabra de Dios, empoderados por el Espiritu Santo
y hablando el amor de Jesus en todo lo que hacemos"
          />
        </Helmet>
      </React.Fragment>
    );
  }
}

export default PageHelmet;
