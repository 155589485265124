import React, { useEffect, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import {
  FiCast,
  FiLayers,
  FiUsers,
  FiMonitor,
  FiChevronUp,
} from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";
import BreadcrumbFullLife from "./common/FullLifeBreadcrumb/BreadcrumbImg";
import ModalVideo from "react-modal-video";
import FooterTwo from "../component/footer/FooterTwo";
import GalleryAboutUs from "../blocks/GalleryGeneric";
import WordPressAPI from "../services/WordPressAPI";
import EnQueCreemos from "../blocks/EnQueCreemos";

const Service = () => {
  const [gallery, setGallery] = React.useState([]);
  const [loadingGallery, setLoadingGallery] = React.useState(true);

  useEffect(() => {
    const api = new WordPressAPI();

    // Fetching gallery
    api
      .getAboutUsGallery()
      .then((data) => {
        setGallery(data[0].acf.galeria_fotos);
        setLoadingGallery(false);
      })
      .catch((error) => {
        console.error("Error fetching ministerios:", error);
        setLoadingGallery(false);
      });
  }, []);
  return (
    <React.Fragment>
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
      <PageHelmet pageTitle="Quienes Somos" />
      <HeaderThree logo="dark" />

      {/* Start Breadcrump Area */}
      <BreadcrumbFullLife title={"Nosotros"} />
      {/* End Breadcrump Area */}

      {/* Start Service Area */}
      <div className="service-area ptb--60 bg_color--5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mb--30">
                <h2>¿Quiénes somos?</h2>
                <p>
                  Amamos a Jesús y lo seguimos. En Full Life conocerás más a
                  Dios, experimentarás Su amor y Su poder. También encontrarás
                  una familia y podrás escuchar prácticos mensajes que
                  enriquecerán tu vida, alimentarán tu espíritu y sanarán tu
                  corazón.
                </p>
                <p>
                  El propósito de Full Life es levantar hombres y mujeres
                  apasionados por Jesús, que usen el empoderamiento del Espíritu
                  Santo y la dirección de Dios, para construir vidas abundantes
                  y ser testigos de Jesús en todas las áreas de sus vidas.
                </p>
                <p>
                  Estamos comprometidos en hacer las obras de Jesús:
                  Compartiendo las buenas nuevas, enseñando Su palabra,
                  mostrando Su amor y haciendo uso de Su poder y autoridad dados
                  a la iglesia.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-service-details ptb--60 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  {/* Start Single Area */}
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="https://hub.iglesiafulllife.org/wp-content/uploads/2024/02/foto-28-min-scaled.jpg"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        <h3>Nuestro Sueño (Visión)</h3>
                        <p>
                          Reflejar la huella de Jesús en todas las áreas de
                          nuestra vida: ESPIRITUAL, RELACIONAL, FÍSICA,
                          PROFESIONAL Y MINISTERIAL; impactando nuestra familia,
                          sociedad y los lugares de mayor necesidad.
                        </p>
                        <h3>Nuestro Proposito (Misión)</h3>
                        <p>
                          Viviendo por principios y no por emociones: Guiados
                          por la palabra de Dios, empoderados por el Espíritu
                          Santo y hablando el amor de Jesús en todo lo que
                          hacemos.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End Single Area */}

                  {/* Start Single Area */}
                  <div className="row sercice-details-content align-items-center">
                    <div className="col-lg-6 col-12 order-2 order-lg-1">
                      <div className="details mt_md--30 mt_sm--30">
                        <h3>Nuestros Pastores</h3>
                        <p>
                          El Pastor Pedro F. Villegas nació en Bogotá, Colombia.
                          Por muchos años se destacó trabajando como diseñador
                          multimedia, gerencia de marca y mercadeo en el campo
                          secular. A los 23 años entregó su vida al Señor y
                          desde entonces mostró una gran pasión por Jesucristo y
                          Su obra. Desde entonces, empezó su preparación
                          ministerial y en el año 2009, junto con su esposa Ana
                          María, se mudaron a Miami, Florida como pastores de
                          jóvenes de la iglesia Casa Sobre La Roca.
                        </p>
                        <p>
                          En el año 2013, el Pastor Pedro y su esposa Ana María,
                          fundaron la iglesia Full Life, congregación que ha
                          venido creciendo e impactando de gran manera. Su
                          mensaje se ha caracterizado por enseñar al cristiano a
                          reflejar a Jesús en todas las áreas de su vida
                          (Espiritual, Relacional, Física, Profesional y
                          Ministerial) por medio de vivir La Palabra de Dios
                          siempre y en todo lo que hace.
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-6 col-12 order-1 order-lg-2">
                      <div className="thumb position-relative">
                        <img
                          className="w-100"
                          src="https://hub.iglesiafulllife.org/wp-content/uploads/2024/02/fotos-pas-scaled.jpg"
                          alt="Service Images"
                        />
                        {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button> */}
                      </div>
                    </div>
                  </div>
                  {/* End Single Area */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-area ptb--60 bg_color--5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mb--30">
                <p>
                  Su esposa, la Pastora Ana María, es psicóloga con Master en
                  Consejería Cristiana y tiene gran experiencia en consejería de
                  niños, jóvenes, mujeres y familia en general. Juntos pastorean
                  la iglesia Full Life, la cual está bajo la cobertura de la
                  iglesia Doral Jesus Worship Center y sus Pastores Frank y
                  Zayda López.
                </p>
                <p>
                  Los Pastores Pedro y Ana después de 7 años batallar en contra
                  de la infertilidad y la esterilidad y siguiendo la voz de
                  Dios, dieron inicio a HOPE, ministerio diseñado para equipar y
                  ministrar a parejas, y sus familiares, que están enfrentando
                  al enemigo de la infertilidad y la esterilidad y que quieren
                  derrotarlo de la mano de Dios. ¡Dios es el único dador de la
                  vida!
                </p>
                <p>
                  HOPE ha sido herramienta de Dios para ayudar y bendecir a
                  muchas parejas a alcanzar su sueño de ser padres y a
                  equiparlos para vivir su espera desde la victoria.
                </p>
                <p>
                  Entre los muchos testimonios se encuentra el de su hijo Natán,
                  quien nació el 2 de octubre del 2015 después de 8 años de
                  espera. Y su hijo Abel quien nació el 21 de julio de 2017, con
                  quien Dios terminó de convertir su área de lucha en una doble
                  porción.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loadingGallery ? (
        <div>Loading gallery...</div>
      ) : (
        <GalleryAboutUs data={gallery} />
      )}

      <div className="extra-margin">
        <EnQueCreemos />
      </div>
      <FooterTwo />
    </React.Fragment>
  );
};

export default Service;
