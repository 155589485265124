import React, { useState, useEffect } from "react";
import "./BlurComponent.css"; // Assuming styles are in a separate CSS file
import { Modal, Button } from "react-bootstrap";

const Stream = () => {
  const [streamKey, setStreamKey] = useState(""); // State for storing the stream key from API
  const [inputStreamKey, setInputStreamKey] = useState(""); // State for user input stream key
  const [isAuthenticated, setIsAuthenticated] = useState(false); // State to control modal visibility
  const [showModal, setShowModal] = useState(false);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleStreamKeySubmit();
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Fetch stream key from Firebase Cloud Function
  useEffect(() => {
    const fetchStreamData = async () => {
      try {
        const response = await fetch(
          "https://us-central1-iglesia-full-life-e6706.cloudfunctions.net/getLivestreamData"
        );
        const data = await response.json();
        if (data && data[0]) {
          setStreamKey(data[0].streamKey); // Store the stream key in the state
        } else {
          console.error("No stream data found in the response");
        }
      } catch (error) {
        console.error("Error fetching livestream data:", error);
      }
    };

    fetchStreamData();
  }, []);

  // Handle stream key input
  const handleStreamKeySubmit = () => {
    if (inputStreamKey === streamKey) {
      setIsAuthenticated(true); // Close the modal if the stream key matches
    } else {
      setShowModal(true); // Show modal if the stream key is incorrect
    }
  };

  return (
    <div className="stream-page">
      {/* Background shapes */}
      <div className="blur-container" style={{ "--blur": "12vw" }}>
        {/* Shapes for background */}
        <div
          className="shape"
          style={{ "--path": "polygon(50.9% 37.2%, ...)" }}
        ></div>
        <div
          className="shape"
          style={{
            "--path": "polygon(50.9% 37.2%, ...)",
            "--offset": "180deg",
            "--speed": "6000ms",
            "--background": "linear-gradient(cyan, blue, green, purple, cyan)",
          }}
        ></div>
      </div>

      {/* Modal for stream key input */}
      {!isAuthenticated && (
        <div className="modal-overlay">
          <div className="modal-content">
            <img
              src="https://adornate.iglesiafulllife.org/wp-content/uploads/2024/06/logo_congreso.png"
              alt="Congreso Logo"
              width="30%"
              height="30%"
            />

            <h2>Ingresa la contraseña</h2>
            <input
              type="password"
              value={inputStreamKey}
              onChange={(e) => setInputStreamKey(e.target.value)}
              placeholder="Ingresa la contraseña enviada por correo"
              onKeyDown={handleKeyPress}
            />
            <button onClick={handleStreamKeySubmit}>Enviar</button>
            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header>
                <Modal.Title>Error</Modal.Title>
              </Modal.Header>
              <Modal.Body>Contraseña Incorrecta</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      )}

      {/* Main content - Two columns with Vimeo embeds */}
      {isAuthenticated && (
        <div className="content-wrapper">
          {/* Add the logo image above the two columns */}
          <div className="centered-logo">
            <img
              src="https://adornate.iglesiafulllife.org/wp-content/uploads/2024/06/logo_congreso.png"
              alt="Congreso Logo"
              className="conference-logo"
            />
          </div>

          <div className="video-columns">
            {/* First column with Vimeo video */}
            <div className="video-column">
              <h3>Conferencia 1</h3>
              <iframe
                  src="https://player.vimeo.com/video/1014202749?h=afbda3fc1f&badge=0&autopause=0&player_id=0&app_id=58479"
                  width="640"
                height="360"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                title="Conferencia 1"
              ></iframe>
            </div>

            {/* Second column with Vimeo video */}
            <div className="video-column">
              <h3>Conferencia 2</h3>
              <iframe
                src="https://player.vimeo.com/video/1014233424?h=32a14c5b74&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" // Replace with actual Vimeo video ID
                width="640"
                height="360"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                title="Conferencia 2"
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Stream;