import React, { useEffect } from 'react';

const SubSplashScriptComponent = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://dashboard.static.subsplash.com/production/web-client/external/embed-1.1.0.js";
    script.type = 'text/javascript';
    script.async = true;
    script.onload = () => {
      // Access the global function safely
      window.subsplashEmbed && window.subsplashEmbed("+a213/lb/li/+8c43476?embed&branding&&1596047265988","https://subsplash.com/","subsplash-embed-j6xcg3n");
    };
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  

  return <div id="subsplash-embed-j6xcg3n" />;
};

export default SubSplashScriptComponent;
