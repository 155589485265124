import React, { Component } from "react";

class ContactThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rnName: "",
      rnEmail: "",
      rnSubject: "",
      rnMessage: "",
      emailSent: false,
      sending: false,
    };
  }
  validateForm = () => {
    // Basic validation - checks if fields are not empty
    const { rnName, rnEmail, rnSubject, rnMessage } = this.state;
    return rnName && rnEmail && rnSubject && rnMessage;
  };

  sendEmail = () => {
    if (this.validateForm()) {
      this.setState({ sending: true });

      // Your lamda api
      const endpoint = "https://ybilgjt4fikzgii36qfu5ha7jy0ztohd.lambda-url.us-east-1.on.aws/";

      // Data to be sent to the Lambda function
      const formData = {
        rnName: this.state.rnName,
        rnEmail: this.state.rnEmail,
        rnSubject: this.state.rnSubject,
        rnMessage: this.state.rnMessage,
      };

      fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
           this.setState({
            emailSent: true,
            sending: false,
            rnName: "",
            rnEmail: "",
            rnSubject: "",
            rnMessage: "",
          });
        })
        .catch((error) => {
          console.error("Error:", error);
          this.setState({ sending: false });
          alert("Error al enviar el mensaje. Intenta nuevamente mas tarde.");
        });
    } else {
      // Form validation failed
      console.error("Validation failed");
    }
  };
  render() {
    const { emailSent, sending } = this.state;

    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
              <h4 className="title">Escríbenos a <a href="mailto:contacto@iglesiafulllife.org">contacto@iglesiafulllife.org</a> o completa el siguiente formulario:</h4>
                <p className="description">6411 TAFT ST, HOLLYWOOD, FL 33024</p>
              </div>
              <div className="form-wrapper">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.sendEmail();
                  }}
                >
                  <label htmlFor="item01">
                    <input
                      type="text"
                      name="name"
                      id="item01"
                      value={this.state.rnName}
                      onChange={(e) => {
                        this.setState({ rnName: e.target.value });
                      }}
                      placeholder="Tu Nombre *"
                    />
                  </label>

                  <label htmlFor="item02">
                    <input
                      type="text"
                      name="email"
                      id="item02"
                      value={this.state.rnEmail}
                      onChange={(e) => {
                        this.setState({ rnEmail: e.target.value });
                      }}
                      placeholder="Tu Email*"
                    />
                  </label>

                  <label htmlFor="item03">
                    <input
                      type="text"
                      name="subject"
                      id="item03"
                      value={this.state.rnSubject}
                      onChange={(e) => {
                        this.setState({ rnSubject: e.target.value });
                      }}
                      placeholder="Escribe tu Asunto"
                    />
                  </label>
                  <label htmlFor="item04">
                    <textarea
                      type="text"
                      id="item04"
                      name="message"
                      value={this.state.rnMessage}
                      onChange={(e) => {
                        this.setState({ rnMessage: e.target.value });
                      }}
                      placeholder="Tu Mensaje"
                    />
                  </label>
                  <button
                    className="rn-button-style--2 btn-solid"
                    type="submit"
                    disabled={sending}
                  >
                    {sending ? "Enviando..." : "Enviar"}
                  </button>
                </form>
                {emailSent && <p>Correo enviado exitosamente!</p>}
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                <img
                  src={`${this.props.contactImages}`}
                  alt="Iglesia Full Life"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactThree;
