import React from "react";
import SingleCarousel from "./SingleCarousel";

const NuestrasReuniones = () => {
   
  return (
    <>
      {/* Start About Area  */}
      <div className="rn-about-area ptb--120 bg_color--3">
        <div className="container">
          <div className="row row--35 align-items-center">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="about-inner inner">
                <div className="section-title">
                  <h2 className="title">Nuestras Reuniones</h2>
                  <p className="description">
                   <b>Domingos</b>
                   <br />
                 

                  </p>
                  <h3> 9:00 A.M., 11:00 A.M.<br /><br /> 1:00 P.M</h3>
                </div> 
                {/* <div className="mt--30">
                  <h4>Lorem ipsum dolor sit.</h4>
                  <ul className="list-style--1">
                    {namesItemOne.map((name, index) => {
                      return (
                        <li key={index}>
                          <FiCheck /> {name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="mt--30">
                  <h4>Lorem ipsum dolor sit.</h4>
                  <ul className="list-style--1">
                    {namesItemTwo.map((name, index) => {
                      return (
                        <li key={index}>
                          <FiCheck /> {name}
                        </li>
                      );
                    })}
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail position-relative">
                <SingleCarousel />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NuestrasReuniones;
