import React from "react";
import Slider from "react-slick";

// This function will automatically import all images from the './images' folder
function importAll(r) {
    return r.keys().map(r);
}

// Importing all images from './images' folder
const images = importAll(require.context('../../../images/church-events', false, /\.(png|jpe?g|svg)$/));

const SingleCarousel = () => {
    // Settings for react-slick
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: true,
        lazyLoad: 'progressive',
        className: 'white-slider'
    };

    return (
        <Slider {...settings}>
            {images.map((image, index) => (
                <div key={index}>
                    <img src={image} alt={`Slide ${index}`} />
                </div>
            ))}
        </Slider>
    );
};

export default SingleCarousel;
