import React, { useState, useEffect } from 'react';

const imageUrls = [
  'assets/images/appfotos/1.png',
  'assets/images/appfotos/2.png',
  'assets/images/appfotos/3.png',
  'assets/images/appfotos/4.png',
  'assets/images/appfotos/5.png',
  'assets/images/appfotos/6.png',
  'assets/images/appfotos/7.png',
  'assets/images/appfotos/8.png',
  'assets/images/appfotos/9.png',
  'assets/images/appfotos/10.png',
  'assets/images/appfotos/11.png',
  'assets/images/appfotos/12.png', 
  
];

//randomize imageUrls
imageUrls.sort(() => Math.random() - 0.5);

const HomeSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === imageUrls.length - 1 ? 0 : prevSlide + 1
      );
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, []);

  return (
    <div className="home-slider" style={{ maxWidth: '100%', overflow: 'hidden', paddingTop:'90px' }}>
      <img src={imageUrls[currentSlide]} alt={`Slide ${currentSlide}`} style={{ width: '100%', display: 'block' }} />
    </div>
  );
};

export default HomeSlider;
