const femaleDiscipuladores = [
    { name: "SONIA MEDINA", ministry: "FULLSTAR" },
    { name: "ANDREA ROZO", ministry: "FULLHEART" },
    { name: "VERONICA VERGARA", ministry: "LIFE CONNECT" },
    { name: "GIANINNA LOMBARDI", ministry: "LIBRESPAMAR" },
    { name: "ONELIA LOPEZ", ministry: "FULL FREEDOM" },
    { name: "MICHELL MACKHOUL", ministry: "AROMA CELES" },
    { name: "ANDREA RAMIREZ", ministry: "ONE WAY" },
    { name: "PAOLA  RODAS", ministry: "STAF" },
    { name: "ANGELA PARDO", ministry: "TRADUCCION" },
    { name: "CAROLINA SOLANO", ministry: "LIFE PRODUCTION" },
    { name: "MAFE SHEPUT", ministry: "SANIDAD" },
    { name: "ISABEL OCHOA", ministry: "FULLPRINT" },
    { name: "DANIELA BETANCOURT", ministry: "ALABANZA" },
    { name: "ANGIE CORONADO", ministry: "ADMINISTRACION" },
    { name: "NATI DIAZ", ministry: "FULLPRINT" },
    { name: "JENNY GARCIA", ministry: "HIGH SCHOOL" },
    { name: "PAOLA VASQUEZ", ministry: "HIGH SCHOOL" },
    { name: "DIANITA DORESTE", ministry: "HIGH SCHOOL" },
    { name: "VALERIA PLATA", ministry: "LEGACY" },
    { name: "JENNIFER GARCIA", ministry: "LEVELS" },
    { name: "MAGGY SALAZAR", ministry: "NEXUS" },
    { name: "ELIANA MORENO", ministry: "REDES" },
    { name: "MARIA ALFA URREA" },
    { name: "LIZ RAMIREZ", ministry: "ORACION" },
    { name: "KAROL CRUZ" },
    { name: "JANETH SOSA" },
    { name: "MARCELA BETANCUR" },
    { name: "PATY VILLEGAS" },
    { name: "MARIA CRISTINA PAEZ" },
    { name: "BRENDA FAJARDO" },
    { name: "BLANCA TORO", ministry: "LIBRESPAMAR" },
    { name: "MARIA A. OSTEICOECHEA" },
    { name: "PATRICIA ZEA", ministry: "FULLHOUSE" },
    { name: "STEFANNY GRAJALES", ministry: "FULLHOUSE" },
    { name: "ERICA PAREDES", ministry: "FULLHOUSE" },
    { name: "DIANA LOPEZ", ministry: "FULLHOUSE" },
    { name: "IVANIA HERNANDEZ", ministry: "FULLHOUSE" },
    { name: "NANCY NARANJO", ministry: "FULLHOUSE" },
    { name: "ROSITA RAMOS", ministry: "FULLHOUSE" },
    { name: "ANDREA ARISTIZABAL", ministry: "CAPTURING" },
    { name: "VANESSA RODRIGUEZ" },
    { name: "OTRA" },
  ];
  
  const maleDiscipuladores = [
    { name: "RICARDO MEDINA", ministry: "FULLSTAR" },
    { name: "ANDRES ROZO", ministry: "FULLHEART" },
    { name: "DANIEL VERGARA", ministry: "LIFE CONNECT" },
    { name: "WILLY SANCHEZ", ministry: "LIBRESPAMAR" },
    { name: "LUIS SIERRALTA", ministry: "FULL FREEDOM" },
    { name: "MATIAS CACERES", ministry: "AROMA CELES" },
    { name: "RICARDO RAMIREZ", ministry: "ONE WAY" },
    { name: "KEMIL ACOSTA", ministry: "STAF" },
    { name: "JONATAN PARDO", ministry: "TRADUCCION" },
    { name: "MIGUEL OSPINA", ministry: "LIFE PRODUCTION" },
    { name: "HENRY LINAREZ", ministry: "DESAYUNOS" },
    { name: "FELIPE OCHOA", ministry: "FULLPRINT" },
    { name: "JOSE BETANCOURT", ministry: "ALABANZA" },
    { name: "DANIEL CORONADO", ministry: "ADMINISTRACION" },
    { name: "ENZO TORRES", ministry: "FULLPRINT" },
    { name: "ANDERSON GARCIA", ministry: "HIGH SCHOOL" },
    { name: "JORGE HURTADO", ministry: "HIGH SCHOOL" },
    { name: "JHONNY FANDINO", ministry: "HIGH SCHOOL" },
    { name: "ALEJO BENAVIDES", ministry: "LEGACY" },
    { name: "HENRY SANCHEZ", ministry: "LEVELS" },
    { name: "RONALD SALAZAR", ministry: "NEXUS" },
    { name: "CAMILO MORENO", ministry: "REDES" },
    { name: "POMPILIO URREA", ministry: "CAMARAS" },
    { name: "JAVI RAMIREZ", ministry: "ORACION" },
    { name: "JUAN SHEPUT", ministry: "SANIDAD" },
    { name: "JAVIER TORO", ministry: "LIBRES PARA AMAR" },
    { name: "MARCOS ZULUAGA", ministry: "LOGISTICA" },
    { name: "EDWIN PLATA", ministry: "FULLHOUSE" },
    { name: "ALEJANDRO HERNANDEZ", ministry: "FULLHOUSE" },
    { name: "NICOLAS CUEZZO", ministry: "FULLHOUSE" },
    { name: "PEDRO PAREDES", ministry: "FULLHOUSE" },
    { name: "JUAN GRAJALES", ministry: "FULLHOUSE" },
    { name: "LUIS A OSPINA", ministry: "CAPTURING" },
    { name: "OTRO" },
  ];
  
  const ministeriosOptions = [
    "One Way",
    "Life Connect",
    "Serve Life",
    "Aroma",
    "Interprete Life",
    "Life Production",
    "Life Store",
    "Full Prints",
    "Production",
    "Capturing Camaras",
    "Full Charge",
    "Full House",
    "High School",
    "Legacy",
    "Levels",
    "Nexus",
    "Full Heart",
    "Full Revival",
    "Adornate",
    "Redes",
    "Bizdom",
    "Full Finance",
  ];
  
  const cursosOptions = [
    "Libres para Amar",
    "Sanidad Interior",
    "Full Start",
    "Full Fit",
    "Full Freedom",
    "Ninguno",
  ];

  export {femaleDiscipuladores, maleDiscipuladores, ministeriosOptions, cursosOptions};