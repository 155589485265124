class WordPressAPI {
  constructor() {
    this.baseURL = process.env.REACT_APP_WORDPRESS_URL;
  }

  // Fetch the entire data
  async getAllMinisterios() {
    try {
      const response = await fetch(
        `${this.baseURL}ministerio?acf_format=standard`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return await response.json();
    } catch (error) {
      console.error(
        "There was a problem with the fetch operation:",
        error.message
      );
    }
  }

  async getMinisterioById(id) {
    try {
      const url = `${this.baseURL}ministerio/${id}?acf_format=standard`;
      const response = await fetch(url);
      const result = await response.json();
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = {
        title: result.title.rendered,
        image: result.acf.ministry_home,
        category: result.acf.categoria_ministerio,
        id: result.id,
        gallery: result.acf.galleria_de_fotos,
        header: result.acf.ministry_single,
        content: result.content.rendered,
      };

      return data;
    } catch (error) {
      console.error(
        "There was a problem with the fetch operation:",
        error.message
      );
    }
  }

  // Fetch transformed data
  async getHomeMinisterios() {
    const rawData = await this.getAllMinisterios();

    if (rawData) {
      return rawData.map((item) => ({
        title: item.title.rendered,
        image: item.acf.ministry_home,
        category: item.acf.categoria_ministerio,
        id: item.id,
      }));
    }
  }

  // Fetch the entire data
  async getAllTestimonios() {
    try {
      const response = await fetch(
        `${this.baseURL}testimonio?acf_format=standard`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return await response.json();
    } catch (error) {
      console.error(
        "There was a problem with the fetch operation:",
        error.message
      );
    }
  }

  // Fetch transformed data
  async getHomeTestimonios() {
    const rawData = await this.getAllTestimonios();
    if (rawData) {
      return rawData.map((item) => ({
        title: item.title.rendered,
        image: item.acf.imagen,
        category: item.acf.categoria,
      }));
    }
  }

  async getAboutUsGallery() {
    try {
      const response = await fetch(
        `${this.baseURL}galeria-about-us?acf_format=standard`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return await response.json();
    } catch (error) {
      console.error(
        "There was a problem with the fetch operation:",
        error.message
      );
    }
  }
}

export default WordPressAPI;
