import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
 

class GalleryGeneric extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab1: 0,
      isOpen: false,
    };
  }
  render() {
    const { tab1, isOpen } = this.state;
    const { data } = this.props; // Assigns `data` prop to a variable
    console.log(data);
    return (
      <div>
        {/* Start Page Wrapper  */}
        <main className="page-wrapper">
          {/* Start Portfolio Area  */}
          <div className="rn-portfolio-area ptb--60 bg_color--1 line-separator">
            <div className="container">
              <div className="row">
                {data.map((value, index) => (
                  <div className="col-lg-2" key={index}>
                    {isOpen && (
                      <Lightbox
                        mainSrc={data[tab1]}
                        nextSrc={data[(tab1 + 1) % data.length]}
                        prevSrc={
                          data[(tab1 + data.length - 1) % data.length]
                        }
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                          this.setState({
                            tab1: (tab1 + data.length - 1) % data.length,
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            tab1: (tab1 + 1) % data.length,
                          })
                        }
                      />
                    )}
                    <div className="item-portfolio-static">
                      <div
                        onClick={() =>
                          this.setState({ isOpen: true, tab1: index })
                        }
                      >
                        <div className="portfolio-static">
                          <div className="thumbnail-inner">
                            <div className="thumbnail">
                              <a href="#portfolio-details">
                                <img
                                  src={value}
                                  alt="Portfolio Images"
                                />
                              </a>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* End Portfolio Area  */}

          {/* End Portfolio Area  */}
        </main>
        {/* End Page Wrapper  */}

        {/* End Footer Area  */}
      </div>
    );
  }
}

export default GalleryGeneric;
