import React from "react";
import EventsCarousel from "../../../EventsCarousel/EventsCarousel";
import "./ExperienciaFullLife.scss";

const ExperienciaFullLife = ({ imagePosition = "left" }) => {
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const currentMonth = new Date().getMonth(); // Get the current month (0-11)
  const Month = months[currentMonth]; // Translate to Spanish

  const handleClick = (nav) => {
    //object literal on x
    const navs = {
      waze: "https://waze.com/ul?ll=25.9875%2C-80.2144&navigate=yes",
      "google-maps": "https://goo.gl/maps/5Y5Z9Z6Z9Z9Z9Z9Z9",
      "apple-maps":
        "https://maps.apple.com/?ll=25.9875%2C-80.2144&q=FullLife%20Church&_ext=EiQpQXJ6Z1ZJQkAxODdLQ0J3WUQ5QkFBQUFBQkFBQUFBLTl4Q0FZQUFBQkFB",
    };
    // open the link in a new tab
    window.open(navs[nav], "_blank");
  };

  const imageComponent = (
    <div className="col-md-6">
      <EventsCarousel />
    </div>
  );

  const contentComponent = (
    <div className="col-md-6 experiencia-fulllife">
      <h2 className="title">Experiencia Full Life</h2>
      <p>
        ¡El domingo es el día del Señor! Te invitamos a vivir la experiencia
        Full Life, enterate de todos los eventos que tenemos este {Month} en
        Full Life. Haz clic en el icono de tu preferencia para obtener
        direcciones.
      </p>
      <p>
        <span>
          Recuenrda, nuestros horarios son - Domingos: 09:00 a.m, 11:00 a.m y
          1:00 p.m. - 6411 Taft St. Hollywood, FL 33024
        </span>
      </p>
      <div className="icons-row">
        <i className="icon icon-waze" onClick={() => handleClick("waze")}></i>
        <i
          className="icon icon-google-maps"
          onClick={() => handleClick("google-maps")}
        ></i>
        <i
          className="icon icon-apple-maps"
          onClick={() => handleClick("apple-maps")}
        ></i>
      </div>
    </div>
  );

  return (
    <div className="container experiencia-full-life">
      <div className="row">
        {imagePosition === "left" ? imageComponent : contentComponent}
        {imagePosition === "left" ? contentComponent : imageComponent}
      </div>
    </div>
  );
};

export default ExperienciaFullLife;
