import React, { useState } from "react";
import ModalVideo from "react-modal-video";

const Testimonios = (props) => {
  const PostList = props.data || [];
  const [isOpen, setIsOpen] = useState(false);
  const [videoId, setVideoId] = useState(null); // Correctly handling dynamic video ID

  const openModal = (id) => {
    setVideoId(id); // Set the correct video ID dynamically
    setIsOpen(true);
  };

  return (
    <>
      {PostList.length > 0 ? (
        <div className="rn-blog-area pt--120 bg_color--1">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-6">
                <div className="section-title text-left">
                  <h2>Testimonios</h2>
                  <p>
                    En Full Life, somos testigos de cómo Dios transforma vidas a
                    diario. Descubre estos impactantes testimonios en video y
                    siente la obra del Espíritu Santo en cada historia
                    compartida.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                  {/* <a className="btn-transparent rn-btn-dark" href="#" disabled><span className="text">Ver todos los testimonios</span></a> */}
                </div>
              </div>
            </div>
            <div className="row mt--60 mt_sm--30">
              {PostList.map((item, i) => (
                <div className="col-lg-4 col-md-6 col-12" key={i}>
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      <a onClick={() => openModal(item.videoId)}>
                        <img
                          className="w-100"
                          src={item.image}
                          alt="Blog Images"
                        />
                      </a>
                    </div>
                    <div className="content">
                      <p className="blogtype">{item.category}</p>
                      <h5 className="title">
                        <a style={{ color: "white" }} onClick={() => openModal(item.videoId)}>
                          {item.title}
                        </a>
                      </h5>
                      <div className="blog-btn">
                        <ModalVideo
                          channel="youtube"
                          isOpen={isOpen}
                          videoId={videoId} // Use the dynamic videoId state
                          onClose={() => setIsOpen(false)}
                        />
                        <a className="rn-btn text-white" onClick={() => openModal(item.videoId)}>
                          Ver Video
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="rn-blog-area pt--120 bg_color--1">
            <div className="container">
              <div className="row align-items-end">
                <div className="col-lg-6">
                  <div className="section-title text-left">
                    <h2>Testimonios</h2>
                    <p>
                      En Full Life, somos testigos de cómo Dios transforma vidas
                      a diario. Descubre estos impactantes testimonios en video
                      y siente la obra del Espíritu Santo en cada historia
                      compartida.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                    {/* <a className="btn-transparent rn-btn-dark" href="#" disabled><span className="text">Ver todos los testimonios</span></a> */}
                  </div>
                </div>
              </div>
              <div className="row mt--60 mt_sm--30">
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      <a onClick={() => openModal("lBS1hquRduA")}>
                        <img
                          className="w-100"
                          src="https://hub.iglesiafulllife.org/wp-content/uploads/2024/02/FULLSTORY_FULLLIFEPOST_FULLSTORY.png"
                          alt="Blog Images"
                        />
                      </a>
                    </div>
                    <div className="content">
                      <p className="blogtype">Testimonio Personal</p>
                      <h4 className="title">
                        <a onClick={() => openModal("lBS1hquRduA")}>
                          Al borde del divorcio
                        </a>
                      </h4>
                      <div className="blog-btn">
                        <ModalVideo
                          channel="youtube"
                          isOpen={isOpen}
                          videoId={videoId} // Use dynamic videoId
                          onClose={() => setIsOpen(false)}
                        />
                        <a className="rn-btn text-white" onClick={() => openModal("lBS1hquRduA")}>
                          Ver Video
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Testimonios;