import React from "react";
import { Tabs, TabPanel } from 'react-tabs';

const AgendaSemana = () => {
    
    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <Tabs>
                        <TabPanel>
                            <div className="rn-testimonial-content text-center">
                                <div className="inner">
                                    <p>Orden y propósito para tu vida.</p>
                                </div>
                                <div className="author-info">
                                    <h6><span>Pastores </span> - Pedro y Ana Maria Villegas.</h6>
                                </div>
                            </div>
                        </TabPanel>
                        
                    </Tabs>
                </div>
            </div>
        </>
    );
}

export default AgendaSemana;
