import React from 'react';

const EnQueCreemos = () => {
  const beliefs = [
    { title: "Dios", text: "Creemos en un solo Dios, creador y sustentador del universo, que se manifiesta eternamente en tres personas, Padre, Hijo y Espíritu Santo, quienes son Uno en ser, esencia y poder." },
    { title: "El Señor Jesucristo", text: "Creemos en la deidad del Señor Jesucristo, en su concepción virginal, en su vida sin pecado, en su muerte expiatoria, en su resurrección corporal, en su exaltación actual a la diestra del Padre y en la “esperanza bienaventurada” de su retorno personal en poder y gloria." },
     { title: "El Espíritu Santo", text: "Creemos en el Espíritu Santo; procede eternamente del Padre y del Hijo; es revelador del conocimiento de Dios al hombre por las Sagradas Escrituras; Santificador, Persuadidor, Consolador, Dador de Poder y representante único de Cristo sobre la Tierra." },
     { title: "La Iglesia", text: "Creemos en la Iglesia, una, santa, universal y apostólica; que es invisible y se compone por las almas de aquellos que tienen escritos sus nombres en el Libro de la Vida; y se manifiesta visiblemente en congregaciones locales, cuya misión es el desarrollo espiritual de los creyentes y la proclamación del Evangelio en el mundo." },
     { title: "La Biblia", text: "Creemos que la Biblia es la plena e infalible Palabra de Dios, revelada al hombre por inspiración del Espíritu Santo y, consecuentemente, única segura norma de fe y conducta." },
     { title: "El estado del ser humano", text: "Creemos que todo ser humano ha pecado, que es culpable ante Dios, y que está bajo condenación eterna." },
     { title: "La Salvación", text: "Creemos que Dios, por medio de la muerte y resurrección de su Hijo Jesucristo, ha provisto la salvación del pecado y que el ser humano, por gracia, puede apropiarse de ella mediante el arrepentimiento y la fe salvadora en Cristo y ser así librado del juicio de Dios, naciendo de nuevo a vida eterna." },
     { title: "Destino Final", text: "Creemos en la resurrección del cuerpo, en el castigo perpetuo de los incrédulos, y en la eterna bienaventuranza de los creyentes en la presencia de Cristo." },
     { title: "Matrimonio", text: "Creemos en la santidad del matrimonio como un pacto delante de Dios entre un hombre y una mujer. Es el matrimonio el núcleo central de la familia." },
  ];

  return (
    <div className="EnQueCreemos container ptb--120">
      <h2 className="title mb-5">EN QUÉ CREEMOS</h2>
      {beliefs.map((belief, index) => (
        <div className="row align-items-center mb-4" key={index}>
          <div className="col-md-4">
            <h4 className="subtitle">{belief.title}</h4>
          </div>
          <div className="col-md-8">
            <p>{belief.text}</p>
          </div>
          <div className="w-100 my-3 border-top"></div> {/* This adds a border between rows */}
        </div>
      ))}
    </div>
  );
};

export default EnQueCreemos;
