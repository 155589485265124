import React, {  } from "react";
import PageHelmet from "../component/common/Helmet";
import {
  FiChevronUp,
} from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import HeaderThree from "../component/header/HeaderThree";
import BreadcrumbFullLife from "./common/FullLifeBreadcrumb/BreadcrumbImg";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "./contact/ContactThree";
 

const Contactanos = () => {
  
  return (
    <React.Fragment>
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
      <PageHelmet pageTitle="Contactanos" />
      <HeaderThree logo="light" />

      {/* Start Breadcrump Area */}
      <BreadcrumbFullLife title={"Contactanos"} image={"https://hub.iglesiafulllife.org/wp-content/uploads/2024/02/1-3-2-scaled.jpg"} />
      {/* End Breadcrump Area */}

      {/* Start Service Area */}
      <div className="service-area ptb--60 bg_color--5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mb--30">
              <ContactThree contactTitle="Contact Us" contactImages="https://hub.iglesiafulllife.org/wp-content/uploads/2024/02/thirdplace-scaled.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
 
 
    
      <FooterTwo />
    </React.Fragment>
  );
};

export default Contactanos;
