import React from "react";
import { Link } from "react-router-dom";
// Import CSS in the same folder
import "./BreadcrumbImg.scss";
import { Parallax } from "react-parallax";

const BreadcrumbFullLife = ({ title, image, strength = 600 }) => {
  const sliderImageDefault =
    image ||
    "https://hub.iglesiafulllife.org/wp-content/uploads/2024/02/JPEG-73-min-scaled.jpg";

  const SlideList = [
    {
      textPosition: "text-center",
      category: "",
      title: title || "Full Life Church",
    },
  ];

  return (
    <>
      <div className="nosotros-header-comp">
        <Parallax
          bgImage={sliderImageDefault}
          strength={strength}
          className="nosotros-header"
          renderLayer={(percentage) => (
            <div
              style={{
                position: 'absolute',
                backgroundImage: `url(${sliderImageDefault})`,
                backgroundSize: '120%',
                backgroundPosition: 'center center',
                width: '100%',
                height: '100%',
              }}
            />
          )}
        >

      
          {SlideList.map((value, index) => (
            <div
              className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center"
              key={index}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category && <span>{value.category}</span>}
                      {value.title && (
                        <h1 className="title theme-gradient">{value.title}</h1>
                      )}
                      {value.description && (
                        <p className="description">{value.description}</p>
                      )}
                      {value.buttonText && (
                        <div className="slide-btn">
                          <a
                            className="rn-button-style--2 btn-solid"
                            href={`${value.buttonLink}`}
                          >
                            {value.buttonText}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Parallax>
      </div>
    </>
  );
};

export default BreadcrumbFullLife;