import React, { Component } from "react";
import { FaTwitter, FaInstagram, FaFacebookF, FaYoutube } from "react-icons/fa";
import { FiX, FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Scrollspy from "react-scrollspy";

const SocialShare = [
  {
    Social: <FaFacebookF />,
    link: "https://www.facebook.com/iglesiafulllife/",
  },
  { Social: <FaYoutube />, link: "https://www.youtube.com/@IglesiaFullLife" },
  {
    Social: <FaInstagram />,
    link: "https://www.instagram.com/iglesiafulllife/",
  },
  { Social: <FaTwitter />, link: "https://twitter.com/IglesiaFullLife/" },
];
class HeaderThree extends Component {
  constructor(props) {
    super(props);
    this.state = { useLightLogo: false, whiteScrollSocial: true };
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    const useLightLogo = window.scrollY > 50;
    this.setState({ useLightLogo });
    this.setState({ whiteScrollSocial: window.scrollY < 50 });
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  stickyHeader() {}

  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });
    const { whiteScrollSocial } =
      this.props.logo == "dark" ? "initial" : this.state;

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { logo, color = "default-color" } = this.props;
    const logoPaths = {
      light: "/assets/images/logo/logo-light.png",
      dark: "/assets/images/logo/logo-black.png",
      "symbol-dark": "/assets/images/logo/logo-black.png",
      "symbol-light": "/assets/images/logo/logo-black.png",
      default: "/assets/images/logo/logo-black.png",
    };

    let logoUrl = this.state.useLightLogo
      ? logoPaths.dark
      : this.props.logo
      ? logoPaths[this.props.logo]
      : logoPaths.default;

    let linkStyle = {};

    if (this.props.logo === "dark") {
      linkStyle.color = "black";
    }

    const isMobile = window.innerWidth < 779;

    return (
      <header className={`header-area header-style-two header--fixed ${color}`}>
        <div className="header-wrapper">
          <div className="header-left d-flex align-items-center">
            <div className="logo">
              <a href="/">
                <img src={logoUrl} alt="Iglesia Full Life" />
              </a>
            </div>
            <nav className="mainmenunav d-lg-block ml--50">
              <ul className="mainmenu">
                <Scrollspy
                  className="mainmenu"
                  items={[
                    "home",
                    "conocenos",
                    "service",
                    "portfolio",
                    "blog",
                    "contact",
                  ]}
                  currentClassName="is-current"
                  offset={-200}
                >
                  <li>
                    <Link to="/" style={linkStyle}>
                      Home
                    </Link>
                  </li>
                  {/* <li>
                  <Link to="/service">Soy Nuevo</Link>
                </li> */}
                  <li>
                    <Link style={linkStyle} to="/conocenos">
                      Nosotros
                    </Link>
                  </li>
                  <li>
                    <Link style={linkStyle} to="/ministerios">
                      Ministerios
                    </Link>
                  </li>
                  <li>
                    <a
                      style={linkStyle}
                      href="https://adornate.iglesiafulllife.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Congreso Levantate
                    </a>
                  </li>
                  <li>
                    <Link style={linkStyle} to="/predicas">
                      Prédicas
                    </Link>
                  </li>
                  {/* <li>
                  <Link style={linkStyle} to="/service">Recursos</Link>
                </li> */}
                  <li>
                    <Link style={linkStyle} to="/contactanos">
                      Contáctanos
                    </Link>
                  </li>
                  <li>
                    {isMobile && (
                      <a
                        href="https://www.purposegateway.com/client/fulllife"
                        target="_blank"
                      >
                        <span>Donaciones</span>
                      </a>
                    )}
                  </li>
                  {/* <li><Link to="/service">Full Prints</Link></li> */}
                  {/* <li className="has-droupdown"><Link to="#">Templates</Link>
                                <ul className="submenu">
                                    <li className="has-droupdown"><Link to="#">Home</Link>
                                        <ul className="submenu">
                                            <li><Link to="/main-demo">Main Demo</Link></li>
                                            <li><Link to="/dark-main-demo">Main Demo Dark</Link></li>
                                            <li><Link to="/creative-agency">Creative Agency</Link></li>
                                            <li><Link to="/creative-landing">Creative One Page</Link></li>
                                            <li><Link to="/creative-portfolio">Creative Portfolio</Link></li>
                                            <li><Link to="/personal-portfolio">Personal Portfolio</Link></li>
                                            <li><Link to="/portfolio-landing">Portfolio One Page</Link></li>
                                            <li><Link to="/dark-portfolio-landing">Portfolio One Page 02</Link></li>
                                            <li><Link to="/digital-agency">Digital Agency</Link></li>
                                            <li><Link to="/startup">Startup</Link></li>
                                            <li><Link to="/paralax">Paralax</Link></li>
                                            <li><Link to="/portfolio-home">Minimal Portfolio</Link></li>
                                            <li><Link to="/business">Business</Link></li>
                                            <li><Link to="/home-particles">Home Particles</Link></li>
                                            <li><Link to="/studio-agency">Studio Agency</Link></li>
                                            <li><Link to="/designer-portfolio">Designer Portfolio</Link></li>
                                            <li><Link to="/interactive-agency">Interactive Agency</Link></li>
                                        </ul>
                                    </li>
                                    <li className="has-droupdown"><Link to="/service">Service</Link>
                                        <ul className="submenu">
                                            <li><Link to="/service">Service</Link></li>
                                            <li><Link to="/service-details">Service Details</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to="/about">About</Link></li>
                                    <li className="has-droupdown"><Link to="#pages">Pages</Link>
                                        <ul className="submenu">
                                            <li><Link to="/blog">Blog List</Link></li>
                                            <li><Link to="/blog-details">Blog Details</Link></li>
                                            <li><Link to="/service">Service</Link></li>
                                            <li><Link to="/service-details">Service Details</Link></li>
                                            <li><Link to="/portfolio">Portfolio</Link></li>
                                            <li><Link to="/portfolio-details">Portfolio Details</Link></li>
                                            <li><Link to="/404">404</Link></li>
                                        </ul>
                                    </li>
                                    <li className="has-droupdown"><Link to="#">Blocks</Link>
                                        <ul className="submenu">
                                            <li><Link to="/portfolio">Portfolio</Link></li>
                                            <li><Link to="/team">Team</Link></li>
                                            <li><Link to="/service">Service</Link></li>
                                            <li><Link to="/video-popup">Video Popup</Link></li>
                                            <li><Link to="/progressbar">Progressbar</Link></li>
                                            <li><Link to="/gallery">Gallery</Link></li>
                                            <li><Link to="/counters">Counters</Link></li>
                                            <li><Link to="/blog">Blog List</Link></li>
                                            <li><Link to="/clint-logo">Clint Logo</Link></li>
                                            <li><Link to="/contact-form">Contact Form</Link></li>
                                            <li><Link to="/google-map">Google Map</Link></li>
                                            <li><Link to="/columns">Columns</Link></li>
                                            <li><Link to="/pricing-table">Pricing Table</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to="/contact">Contact</Link></li>
                                </ul>
                            </li> */}
                </Scrollspy>
              </ul>
            </nav>
          </div>
          <div className="header-right">
            <div className="social-share-inner">
              <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a
                      href={`${val.link}`}
                      style={{ color: whiteScrollSocial ? "white" : "initial" }}
                    >
                      {val.Social}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            {!isMobile && (
              <div className="header-btn">
                <div className="view-more-btn  text-center">
                  <a
                    className="rn-button-style--2 btn-solid"
                    href="https://www.purposegateway.com/client/fulllife"
                    target="_blank"
                  >
                    <span>Donaciones</span>
                  </a>
                </div>
              </div>
            )}
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default HeaderThree;
