import React, { useState } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import HeaderThree from "../component/header/HeaderThree";
import BreadcrumbFullLife from "./common/FullLifeBreadcrumb/BreadcrumbImg";
import FooterTwo from "../component/footer/FooterTwo";
import MultiStepForm from "../home/MultiStepForm/MultiStepForm";
 

const FormularioLideres = () => {
  const [loadingMinisterio, setLoadingMinisterios] = useState(true);
  const [ministerios, setMinisterios] = useState([]);

   
 
  return (
    <React.Fragment>
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
      <PageHelmet pageTitle="Formulario ministerios" />
      <HeaderThree logo="light" />

      {/* Start Breadcrump Area */}
      <BreadcrumbFullLife
        title={" "}
        image={
          "https://hub.iglesiafulllife.org/wp-content/uploads/2024/11/BANNER-PARA-DISCIPULOS_1920X1080.png"
        }
        strength={100}
      />
      {/* End Breadcrump Area */}

      {/* Start Service Area */}
      <div className="portfolio-area   bg_color--5 vh-100">
        <div className="portfolio-sacousel-inner">
         <div className="container">
         <div className="row">
         <div className="col-lg-12 z-10  ">
        
          {/* content goes here */}
          <MultiStepForm  />
          </div>
          </div>
         </div>
        </div>
      </div>
      <FooterTwo />
    </React.Fragment>
  );
};

export default FormularioLideres;
