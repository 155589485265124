import React, { useState, useEffect } from "react";
import "./EventsCarousel.scss";
import Carousel from 'react-bootstrap/Carousel';

const images = [
  "https://hub.iglesiafulllife.org/wp-content/uploads/2024/02/CARRUSEL_FEBRERO_FULLLIFE_02.jpg",
  "https://hub.iglesiafulllife.org/wp-content/uploads/2024/02/CARRUSEL_FEBRERO_FULLLIFE_08.jpg",
  "https://hub.iglesiafulllife.org/wp-content/uploads/2024/02/CARRUSEL_FEBRERO_FULLLIFE_09.jpg",
 
  // Add more images as needed
];

const EventsCarousel = () => {
  
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => {
        if (prevIndex === images.length - 1) {
          return 0;
        } else {
          return prevIndex + 1;
        }
      });
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="EventsCarousel">
      <Carousel activeIndex={index} onSelect={handleSelect}  >
      {images.map((image, index) => (
        <Carousel.Item key={index} className="carousel-item">
          <img
            className="d-block w-100"
            src={image}
            alt={`Slide ${index}`}
          />
          {/* <Carousel.Caption>
            <h3>Slide {index}</h3>
          </Carousel.Caption> */}
        </Carousel.Item>
      ))}
    </Carousel>
    </div>
  );
};

export default EventsCarousel;
