import React, { useEffect, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import HeaderThree from "../component/header/HeaderThree";
import BreadcrumbFullLife from "./common/FullLifeBreadcrumb/BreadcrumbImg";
import FooterTwo from "../component/footer/FooterTwo";
import { useParams } from "react-router-dom";
import PortfolioList from "./portfolio/PortfolioList";
import WordPressAPI from "../services/WordPressAPI";
import GalleryAboutUs from "../blocks/GalleryGeneric";
import HeaderFour from "../component/header/HeaderFour";

const MinisteriosDetail = () => {
  const [loadingMinisterio, setLoadingMinisterios] = useState(true);
  const [ministerios, setMinisterios] = useState({});
  let { ministerio_id } = useParams();
   useEffect(() => {
    const api = new WordPressAPI();

    // Fetching ministerios
    api
      .getMinisterioById(ministerio_id)
      .then((data) => {
        setMinisterios(data);
        setLoadingMinisterios(false);
      })
      .catch((error) => {
        console.error("Error fetching ministerios:", error);
        setLoadingMinisterios(false);
      });

    // Fetching testimonios
  }, []);

 console.log("Entrante", ministerios);

  return (
    <React.Fragment>
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
      <PageHelmet pageTitle="Ministerios" />
      <HeaderFour logo="dark" />

      {/* Start Breadcrump Area */}
      <BreadcrumbFullLife
        title={ministerios.title}
        image={
           ministerios.header
        }
        strength={100}
      />
      {/* End Breadcrump Area */}

      {/* Start Service Area */}
      <div className="service-area ptb--60 bg_color--5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mb--30">
              <div dangerouslySetInnerHTML={{ __html: ministerios.content }}></div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {loadingMinisterio ? (
        <div>Loading gallery...</div>
      ) : (
        <GalleryAboutUs data={ministerios.gallery} />
      )}
      <FooterTwo />
    </React.Fragment>
  );
};

export default MinisteriosDetail;
