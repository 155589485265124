import React from "react";

const Contactanos = () => {
    

    let Data = [
        {
            countNum : '+1(786)678-6441',
            countTitle: '',
        },
    ];

    return(
        <>
            <div className="row contactanos-container">
                {Data.map( (value, index) => (
                    <div className="counterup_style--2 col-lg-4 col-md-4 col-sm-5 col-5" key={index}>
                        <h5 className="counter">
                            {value.countNum}
                        </h5>
                        <p className="description">{value.countTitle}</p>
                    </div>
                ))}
                <div className="contactanos-right col-lg-8 col-md-8 col-sm-7 col-7">
                    <p>6411 Taft St, Hollywood, FL 33024</p>
                    <p className="description">Si tienes alguna inquietud, no dudes en contactarnos a través del correo: contacto@iglesiafulllife.org</p>
                </div>
            </div>
        </>
    )
}

export default Contactanos;
