import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import moment from "moment";
import {
  femaleDiscipuladores,
  maleDiscipuladores,
  ministeriosOptions,
  cursosOptions,
} from "../../constants/form-discipuladores";
import "./MultiStepForm.scss";
import axios from "axios";
import imageCompression from "browser-image-compression";

// Define the form validation schema using Zod
const formSchema = z
  .object({
    nombre: z.string().min(2, {
      message: "El nombre debe tener al menos 2 caracteres.",
    }),
    apellido: z.string().min(2, {
      message: "El apellido debe tener al menos 2 caracteres.",
    }),
    genero: z.enum(["M", "F"], {
      required_error: "Por favor seleccione un género.",
    }),
    estadoCivil: z.string({
      required_error: "Por favor seleccione un estado civil.",
    }),
    hijos: z
      .number()
      .min(0, { message: "El número de hijos no puede ser negativo." })
      .max(5, { message: "El número máximo de hijos es 5." }),
    hijosEdades: z.array(
      z
        .string()
        .nonempty("Debe ingresar una fecha.")
        .refine((value) => moment(value, "YYYY-MM-DD", true).isValid(), {
          message: "Por favor seleccione una fecha válida (YYYY-MM-DD).",
        })
    ),
    discipulador: z.string({
      required_error: "Por favor seleccione un discipulador.",
    }),
    fechaNacimiento: z.string().refine(
      (value) => {
        return moment(value, "YYYY-MM-DD", true).isValid();
      },
      {
        message: "Por favor seleccione una fecha válida (YYYY-MM-DD).",
      }
    ),
    celular: z.string().regex(/^\d{10}$/, {
      message: "Por favor ingrese un número de celular válido (10 dígitos).",
    }),
    selfie: z
      .instanceof(File, {
        message: "Por favor suba una selfie.",
      })
      .refine((file) => file.size <= 7000000, {
        message: "El tamaño máximo del archivo es 7MB.",
      }),
    ministerios: z.array(z.string()).min(1, {
      message: "Por favor seleccione al menos un ministerio.",
    }),
    cursos: z.array(z.string()).optional(),
  })
  .superRefine((data, ctx) => {
    if (data.hijos > 0) {
      if (!data.hijosEdades || data.hijosEdades.length !== data.hijos) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Debe ingresar la fecha de nacimiento de cada hijo.`,
          path: ["hijosEdades"],
        });
      }
    }
  });

export default function MultiStepForm() {
  const [selectedMinisterios, setSelectedMinisterios] = useState([]);
  const [selectedCursos, setSelectedCursos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  const [selfieError, setSelfieError] = useState("");

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      nombre: "",
      apellido: "",
      hijos: 0,
      hijosEdades: [],
      ministerios: [],
      cursos: [],
      discipulador: "",
    },
  });

  const genero = form.watch("genero");
  const hijos = form.watch("hijos");

  useEffect(() => {
    // Reset 'discipulador' when 'genero' changes
    form.setValue("discipulador", "");
  }, [genero]);

  useEffect(() => {
    // Adjust the hijosEdades array length to match the number of hijos
    const currentHijosEdades = form.getValues("hijosEdades") || [];
    const adjustedHijosEdades = currentHijosEdades.slice(0, hijos);
    while (adjustedHijosEdades.length < hijos) {
      adjustedHijosEdades.push("");
    }
    form.setValue("hijosEdades", adjustedHijosEdades);
  }, [hijos]);

  const discipuladores =
    genero === "F"
      ? femaleDiscipuladores
      : genero === "M"
      ? maleDiscipuladores
      : [];

  const toggleMinisterio = (ministerio) => {
    const updatedMinisterios = selectedMinisterios.includes(ministerio)
      ? selectedMinisterios.filter((item) => item !== ministerio)
      : [...selectedMinisterios, ministerio];

    setSelectedMinisterios(updatedMinisterios);
    form.setValue("ministerios", updatedMinisterios);
  };

  const toggleCurso = (curso) => {
    const updatedCursos = selectedCursos.includes(curso)
      ? selectedCursos.filter((item) => item !== curso)
      : [...selectedCursos, curso];

    setSelectedCursos(updatedCursos);
    form.setValue("cursos", updatedCursos);
  };

  const handleSelfieChange = async (e) => {
    setSelfieError("");
    const file = e.target.files?.[0];

    if (file) {
      if (file.size > 7000000) {
        setSelfieError("El tamaño máximo del archivo es 7MB.");
        return;
      }

      try {
        // Compress the image to less than 1MB
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        const compressedBlob = await imageCompression(file, options);

        // Convert the compressed Blob to a File
        const compressedFile = new File([compressedBlob], file.name, {
          type: file.type,
          lastModified: Date.now(),
        });

        // Update the form value with the compressed image
        form.setValue("selfie", compressedFile);

        // Generate a preview URL
        const preview = URL.createObjectURL(compressedFile);
        setPreviewUrl(preview);
      } catch (error) {
        console.error("Error compressing image:", error);
        setSelfieError(
          "Error al comprimir la imagen. Por favor, intente otra imagen."
        );
      }
    }
  };

  async function onSubmit(values) {
    setLoading(true);
    setSuccessMessage(""); // Reset success message
    try {
      const formData = new FormData();

      // Append all form fields to FormData
      for (const [key, value] of Object.entries(values)) {
        if (Array.isArray(value)) {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      }

      // Append the selfie file
      formData.append("selfie", values.selfie);

      // Send the form data to the Cloud Function
      const response = await axios.post(
        "https://submitdiscipulado-4fxkz4ckma-uc.a.run.app/submitDiscipulado",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Form has been submitted and data saved!", response.data);

      // Reset form
      form.reset();
      setSelectedMinisterios([]);
      setSelectedCursos([]);
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
        setPreviewUrl(null);
      }
      setSelfieError("");
      setSuccessMessage("¡Formulario enviado con éxito!");
    } catch (error) {
      console.error("Error saving data: ", error);
      alert(
        "Hubo un error al enviar el formulario. Por favor, inténtelo de nuevo."
      );
    } finally {
      setLoading(false);
    }
  }

  function onError(errors) {
    console.log("Validation Errors:", errors);
  }

  return (
    <div className="form-container">
      <div className="container">
        <div
          className="card shadow-lg mx-auto"
          style={{ maxWidth: "700px", padding: "20px" }}
        >
          <div className="card-body">
            <h4 className="mb-3 text-center">Información Básica</h4>
            <form
              onSubmit={form.handleSubmit(onSubmit, onError)}
              className="row g-3"
            >
              {/* Nombre */}
              <div className="col-md-6">
                <label htmlFor="nombre" className="form-label">
                  Nombre
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="nombre"
                  placeholder="Ingrese su nombre"
                  {...form.register("nombre")}
                />
                {form.formState.errors.nombre && (
                  <div className="invalid-feedback d-block">
                    {form.formState.errors.nombre.message}
                  </div>
                )}
              </div>

              {/* Apellido */}
              <div className="col-md-6">
                <label htmlFor="apellido" className="form-label">
                  Apellido
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="apellido"
                  placeholder="Ingrese su apellido"
                  {...form.register("apellido")}
                />
                {form.formState.errors.apellido && (
                  <div className="invalid-feedback d-block">
                    {form.formState.errors.apellido.message}
                  </div>
                )}
              </div>

              {/* Género */}
              <div className="col-md-6">
                <label htmlFor="genero" className="form-label">
                  Género
                </label>
                <select
                  className="form-select"
                  id="genero"
                  {...form.register("genero")}
                >
                  <option value="">Seleccione su género</option>
                  <option value="M">Masculino</option>
                  <option value="F">Femenino</option>
                </select>
                {form.formState.errors.genero && (
                  <div className="invalid-feedback d-block">
                    {form.formState.errors.genero.message}
                  </div>
                )}
              </div>

              {/* Discipulador */}
              {genero && (
                <div className="col-md-6">
                  <label htmlFor="discipulador" className="form-label">
                    Discipulador
                  </label>
                  <select
                    className="form-select"
                    id="discipulador"
                    {...form.register("discipulador")}
                  >
                    <option value="">Seleccione su discipulador</option>
                    {discipuladores.map((d) => (
                      <option key={d.name} value={d.name}>
                        {d.name} {d.ministry && `- ${d.ministry}`}
                      </option>
                    ))}
                  </select>
                  {form.formState.errors.discipulador && (
                    <div className="invalid-feedback d-block">
                      {form.formState.errors.discipulador.message}
                    </div>
                  )}
                </div>
              )}

              {/* Estado Civil */}
              <div className="col-md-6">
                <label htmlFor="estadoCivil" className="form-label">
                  Estado Civil
                </label>
                <select
                  className="form-select"
                  id="estadoCivil"
                  {...form.register("estadoCivil")}
                >
                  <option value="">Seleccione su estado civil</option>
                  <option value="soltero">Soltero/a</option>
                  <option value="casado">Casado/a</option>
                  <option value="divorciado">Divorciado/a</option>
                  <option value="viudo">Viudo/a</option>
                </select>
                {form.formState.errors.estadoCivil && (
                  <div className="invalid-feedback d-block">
                    {form.formState.errors.estadoCivil.message}
                  </div>
                )}
              </div>

              {/* Hijos */}
              <div className="col-md-6">
                <label htmlFor="hijos" className="form-label">
                  Número de Hijos
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="hijos"
                  min="0"
                  max="5"
                  value={form.watch("hijos")}
                  onChange={(e) => {
                    let value = parseInt(e.target.value, 10);
                    if (isNaN(value) || value < 0) value = 0;
                    if (value > 5) value = 5;
                    form.setValue("hijos", value);
                  }}
                />
                {form.formState.errors.hijos && (
                  <div className="invalid-feedback d-block">
                    {form.formState.errors.hijos.message}
                  </div>
                )}
              </div>

              {/* Edad de los Hijos */}
              {hijos > 0 && (
                <div className="col-md-12">
                  <label className="form-label">Edad de los Hijos</label>
                  {[...Array(hijos)].map((_, index) => (
                    <div key={index} className="mb-2">
                      <label className="form-label">
                        Hijo {index + 1} - Fecha de Nacimiento
                      </label>
                      <input
                        type="date"
                        className={`form-control ${
                          form.formState.errors.hijosEdades &&
                          form.formState.errors.hijosEdades[index]
                            ? "is-invalid"
                            : ""
                        }`}
                        {...form.register(`hijosEdades.${index}`)}
                      />
                      {/* Display validation errors for each child */}
                      {form.formState.errors.hijosEdades &&
                        form.formState.errors.hijosEdades[index] && (
                          <div className="invalid-feedback d-block">
                            {form.formState.errors.hijosEdades[index].message}
                          </div>
                        )}
                    </div>
                  ))}
                  {/* Display general validation error for hijosEdades */}
                  {form.formState.errors.hijosEdades &&
                    typeof form.formState.errors.hijosEdades.message ===
                      "string" && (
                      <div className="invalid-feedback d-block">
                        {form.formState.errors.hijosEdades.message}
                      </div>
                    )}
                </div>
              )}

              {/* Fecha de Nacimiento */}
              <div className="col-md-6">
                <label htmlFor="fechaNacimiento" className="form-label">
                  Fecha de Nacimiento
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="fechaNacimiento"
                  {...form.register("fechaNacimiento")}
                />
                {form.formState.errors.fechaNacimiento && (
                  <div className="invalid-feedback d-block">
                    {form.formState.errors.fechaNacimiento.message}
                  </div>
                )}
              </div>

              {/* Celular */}
              <div className="col-md-6">
                <label htmlFor="celular" className="form-label">
                  Celular
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="celular"
                  placeholder="Ingrese su número de celular"
                  {...form.register("celular")}
                />
                {form.formState.errors.celular && (
                  <div className="invalid-feedback d-block">
                    {form.formState.errors.celular.message}
                  </div>
                )}
              </div>

              {/* Ministerios */}
              <div className="col-md-12">
                <label htmlFor="ministerios" className="form-label">
                  Ministerios
                </label>
                <div className="d-flex flex-wrap gap-2">
                  {ministeriosOptions.map((ministerio) => (
                    <button
                      key={ministerio}
                      type="button"
                      className={`btn btn-sm btn-outline-primary ${
                        selectedMinisterios.includes(ministerio)
                          ? "active"
                          : ""
                      }`}
                      onClick={() => toggleMinisterio(ministerio)}
                    >
                      {ministerio}
                    </button>
                  ))}
                </div>
                {form.formState.errors.ministerios && (
                  <div className="invalid-feedback d-block">
                    {form.formState.errors.ministerios.message}
                  </div>
                )}
              </div>

              {/* Cursos */}
              <div className="col-md-12">
                <label htmlFor="cursos" className="form-label">
                  Cursos
                </label>
                <div className="d-flex flex-wrap gap-2">
                  {cursosOptions.map((curso) => (
                    <button
                      key={curso}
                      type="button"
                      className={`btn btn-sm btn-outline-secondary ${
                        selectedCursos.includes(curso) ? "active" : ""
                      }`}
                      onClick={() => toggleCurso(curso)}
                    >
                      {curso}
                    </button>
                  ))}
                </div>
                {form.formState.errors.cursos && (
                  <div className="invalid-feedback d-block">
                    {form.formState.errors.cursos.message}
                  </div>
                )}
              </div>

              {/* Selfie */}
              <div className="col-md-12">
                <label htmlFor="selfie" className="form-label">
                  Selfie
                </label>
                <div className="row align-items-center">
                  {/* Selfie Input */}
                  <div className="col-md-6">
                    <div className="custom-file-input-wrapper">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="selfie"
                        accept="image/*"
                        onChange={handleSelfieChange}
                        style={{ display: "none" }} // Hide the default file input
                      />
                      <button
                        type="button"
                        className="btn btn-outline-primary w-100"
                        onClick={() => document.getElementById("selfie")?.click()}
                      >
                        Subir Selfie
                      </button>
                      {form.watch("selfie") && (
                        <div className="mt-2">
                          Archivo Seleccionado:{" "}
                          <strong>{form.watch("selfie").name}</strong>
                        </div>
                      )}
                      {selfieError && (
                        <div className="invalid-feedback d-block">
                          {selfieError}
                        </div>
                      )}
                      {form.formState.errors.selfie && (
                        <div className="invalid-feedback d-block">
                          {form.formState.errors.selfie.message}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Image Preview */}
                  <div className="col-md-6">
                    {previewUrl && (
                      <div className="text-center">
                        <img
                          src={previewUrl}
                          alt="Preview"
                          className="rounded-circle"
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Submit Button */}
              <div className="col-12 mt-3">
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Enviando...
                    </>
                  ) : (
                    "Enviar"
                  )}
                </button>
              </div>
            </form>

            {/* Success Message */}
            {successMessage && (
              <div className="alert alert-success mt-3" role="alert">
                {successMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}