// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

// Home layout

// Dark Home Layout 

// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";

// Blocks Layout

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import Home from './home/Home';
import Predicas from './elements/Predicas';
import Contactanos from './elements/Contactanos';
import Ministerios from './elements/Ministerios';
import MinisteriosDetail from './elements/MinisteriosDetail';
import ExternalRedirect from './component/common/ExternalRedirect';
import Stream from './home/Stream';
import FormularioLideres from './elements/FormularioLideres';
 

class Root extends Component {
    render() {
        return (
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        
                        {/* Element Layout */}
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
                        <Route exact path={`${process.env.PUBLIC_URL}/conocenos`} component={Service} />
                        <Route exact path={`${process.env.PUBLIC_URL}/contactanos`} component={Contactanos} />
                        <Route exact path={`${process.env.PUBLIC_URL}/ministerios`} component={Ministerios} />
                        <Route exact path={`${process.env.PUBLIC_URL}/ministerios/:ministerio_id`} component={MinisteriosDetail} />
                        <Route exact path={`${process.env.PUBLIC_URL}/predicas`} component={Predicas} />
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details`} component={ServiceDetails} />
                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact} />
                        <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About} />
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details`} component={PortfolioDetails} />
                        <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog} />
                        <Route exact path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails} />
                        <Route exact path={`${process.env.PUBLIC_URL}/stream`} component={Stream} />
                        <Route exact path={`${process.env.PUBLIC_URL}/formulario-lideres`} component={FormularioLideres} />

                        {/* Blocks Elements  */}
                        

                        {/* Redirect Route */}
                        <Route exact path={`${process.env.PUBLIC_URL}/campamento-jovenes`} render={() => <ExternalRedirect to="https://iglesiafulllife.regfox.com/resurgence-2024/" />} />

                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
                        <Route component={error404} />

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        );
    }
} 

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();
